import React, { useEffect, useState } from 'react'
import coreService from "../../../../../../services/core.service";
import LockIcon from '@mui/icons-material/Lock';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { t } from 'i18next';


function DiscoverMoreCard({ discoverMoreAcademy }) {
  const [nextModuleThumbnail, setNextModuleThumbnail] = useState('');

  useEffect(() => {
    if (discoverMoreAcademy.academy && discoverMoreAcademy.academy.next_module && discoverMoreAcademy.academy.next_module.next_module_video_url) {
      coreService
        .getModuleVideoThumbnail(discoverMoreAcademy.academy.next_module.next_module_video_url.split("/").pop())
        .then((res) => {
          setNextModuleThumbnail(res.data)
        }).catch(e => {
          setNextModuleThumbnail(undefined)
        })
    }
    else
      setNextModuleThumbnail(undefined)
  }, [discoverMoreAcademy])

  const style = {}
  if(nextModuleThumbnail || (discoverMoreAcademy.academy && discoverMoreAcademy.academy.background)) {
    style['backgroundImage'] = `url(${nextModuleThumbnail ?? discoverMoreAcademy.academy.background})`;
    style['backgroundSize'] = 'cover';
    style['backgroundBlendMode'] = 'overlay';
    style['backgroundColor'] = 'rgba(0, 0, 0, 0.25)';

  }

  return (
    <div className="discover_more_container" onClick={() => {
      window.location.href = discoverMoreAcademy.link;
    }}>
      <div className="image" style={style}>
        <div className="image_overlay" />
      </div>
      <div className="icon-wrapper">
        <LockIcon />
      </div>
      <div className="content">
        <h3 className="title">{discoverMoreAcademy.academy && discoverMoreAcademy.academy.name}</h3>
        <p className="prices_wrapper">
          <span className={discoverMoreAcademy.sale_price ? "price discounted" : "price"}>${discoverMoreAcademy.price}</span>
          {discoverMoreAcademy.sale_price && <span className="sale_price">${discoverMoreAcademy.sale_price}</span>}
        </p>
        <p className="description">{discoverMoreAcademy.academy && discoverMoreAcademy.academy.description}</p>
      </div>
      <div className="actions-wrapper">
        <p className="goto-link">{t('Go to course page')}</p>
        <button className='goto-icon-wrapper'>
          <ArrowForwardOutlinedIcon className='goto-icon' />
        </button>
      </div>
    </div>
  );
}

export default DiscoverMoreCard
